import React from 'react';
import tdShopify from './assets/tdShopifySnippet240v.png';
import tdIkaProj from './assets/tdIkaProjSnippet240v.png';
import tdNousagi from './assets/nousagi240v.png';
import tdREProj from './assets/tdREProjSnippet240v.png';
import tdNsgReact from './assets/nsgreact240v.png';
import tdDOBBanner from './assets/Bnr_DestructOBall.jpg';
import tdDOBapk from './assets/DestructOBallv0.0.1.apk';
import tdDOBvid from './assets/ProjectDestructOBallVid.mp4';
import tdProjCityBnr from './assets/Bnr_ProjectCity.png';
import tdProjCityVid from './assets/ProjectCity Vid.mp4';

//TODO: TURN STUFF INTO COMPONENTS

function Projects() {
	return (
		<div className="container mt-5 td_fFontSz2">
			<div className="row mt-2 text-center">
				<h1>Web</h1>
				<hr />
			</div>
			<div className="row mt-2">
				<div className="col-12 col-lg-4 text-center">
					<a href="https://tirp-dev.myshopify.com/" target="_blank">
						<img src={tdShopify} alt="Tirp Dev Shopify" className="tdSP240v" />
						<h3>E-Commerce Template</h3>
					</a>
				</div>
				<div className="col-12 col-lg-8">
					<div className="row homeText">
						<p>Unleash the power of your online business with our stunning, responsive e-commerce template! Elevate your store's online presence with a modern, eye-catching design that captivates shoppers from the first click. Seamlessly showcase your products and create an unforgettable shopping experience. Make the leap to success today with our template – where style meets sales!
							<br /><br />
							<b>Access Password: tirpdev</b>
							<br /><br />
							<b>Tech Stack: Shopify</b>
						</p>
					</div>
				</div>
			</div>
			<hr />
			<div className="row mt-2">
				<div className="col-12 col-lg-4 order-lg-2 text-center">
					<a href="/IkaProj" target="_blank">
						<img src={tdIkaProj} alt="Ikariam Analyzinator" className="tdSP240v" />
						<h3>Ikariam Analyzinator</h3>
					</a>
				</div>
				<div className="col-12 col-lg-8 order-lg-1">
					<div className="row homeText">
						<p>This we bpage is created to provide a tool that can calculate the stats of the game for you. No more hassle of manually calculating it or entering it on excel and creating formulas. The tool will do it for you! Below are listed language/program that was used for it.
							<br /><br />
							<b>Tech Stack: Javascript, JQuery, PHP, CSS, Bootstrap, HTML</b>
						</p>
					</div>
				</div>
			</div>
			<hr />
			<div className="row mt-2">
				<div className="col-12 col-lg-4 text-center">
					<a href="/blog" target="_blank">
						<img src={tdNousagi} alt="Tirp Dev Blog" className="tdSP240v" />
						<h3>Tirp Dev Blog</h3>
					</a>
				</div>
				<div className="col-12 col-lg-8">
					<div className="row homeText">
						<p>Delving into other tech stacks, I've created my own blog using wordpress! ^_^<br />Go check it out as I use it as my diary on how my developer journey goes. I'm excited to see what you guys think
							<br /><br />
							<b>Tech Stack: Wordpress</b>
						</p>
					</div>
				</div>
			</div>
			<hr />
			<div className="row mt-2">
				<div className="col-12 col-lg-4 order-lg-2 text-center">
					<a href="/REProj" target="_blank">
						<img src={tdREProj} alt="Tirp Dev Real Estate Template" className="tdSP240v" />
						<h3>Real Estate Web Template</h3>
					</a>
				</div>
				<div className="col-12 col-lg-8 order-lg-1">
					<div className="row homeText">
						<p>A template that can be used to create a real estate site. With this, we can use it as a foundation when creating a website for you. More Features and adjustments can be made and this will become a good front website for your real estate company.
							<br /><br />
							<b>Tech Stack: Bootstrap, CSS, HTML</b>
						</p>
					</div>
				</div>
			</div>
			<hr />
			<div className="row mt-2">
				<div className="col-12 col-lg-4 text-center">
					<a href="/">
						<img src={tdNsgReact} alt="Tirp Dev with React" className="tdSP240v" />
						<h3>Tirp Dev</h3>
					</a>
				</div>
				<div className="col-12 col-lg-8">
					<div className="row homeText">
						<p>This website is primarily created with the React.js architecture in mind for it's scalability and reusability. Then together with Bootstrap, I made it into a flexible site that adjusts the layout depending on the user's screen.
							<br /><br />
							<b>Tech Stack: React.js, Bootstrap, CSS, HTML</b>
						</p>
					</div>
				</div>
			</div>
			<div className="row mt-2 text-center">
				<h1>Mobile Apps</h1>
				<hr />
			</div>
			<div className="row mt-2">
				<div className="col-12 col-lg-4 text-center">
					<a href={tdDOBapk} download>
						<img src={tdDOBBanner} alt="Destruct O Ball APK" className="tdSP240v" />
						<h3>Destruct O' Ball, click to download apk</h3>
					</a>
				</div>
				<div className="col-12 col-lg-8">
					<div className="row homeText">
						<p>While I was going through some of my old files, I found an apk of a game that I created before(Aug 2019). It's built within 2 days using Unity and the code is in C#. 
						Pardon the ads on this apk because when I tried to remove it through Unity Services, the app broke... so I let it stay there.<br />
						Watch the clip to see the preview. The video is on the newer version of the app but the downloadable apk is on the original version.
						I no longer have the source code and the apk for the app on the vid doesn't work.
							<br /><br />
							<b>Tech Stack: C#, Unity</b>
						</p>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-3"></div>
				<div className="col-12 col-lg-6">
					<video controls className="td_ProjVid">
						<source src={tdDOBvid} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
				<div className="col-lg-3"></div>
			</div>
			<hr />
			<div className="row mt-5">
				<div className="col-12 col-lg-4 text-center">
					<img src={tdProjCityBnr} alt="Project City (shelved)" className="tdSP240v" />
					<h3>Project City (shelved July 2019)</h3>
				</div>
				<div className="col-12 col-lg-8">
					<div className="row homeText">
						<p>This was one of my over ambitious project. It doesn't look like it but it's actually a very deep and overly complicated game and was inspired by Patrician IV. 
						It's a city building game that has an overly complicated trade system and it also have a warring system that wasn't shown in the clip.
						I stopped developing the game as I realized how much it would take me to finish it with my skills at that time.
							<br /><br />
							<b>Tech Stack: C#, Unity</b>
						</p>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-3"></div>
				<div className="col-12 col-lg-6">
					<video controls className="td_ProjVid">
						<source src={tdProjCityVid} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
				<div className="col-lg-3"></div>
			</div>
		</div>
	);
}

export default Projects;