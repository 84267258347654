import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import nousagiIcon from './assets/nousagiIcon.png';

function Header() {
	const hLinks = [
		{id: 0, link: "/", text: "Home"},
		{id: 1, link: "/about", text:"About"},
		{id: 2, link: "/projects", text:"Sample Projects"},
	];
	
	return (
		<Navbar expand="lg" className="td_cScheme1">
			<Container>
				<Navbar.Brand href="/" className="td_whiteT">
					<img src={nousagiIcon} alt="Tirp Dev Icon" className="iconSz2" />
					Tirp Dev
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						{hLinks.map((hLink) => (
							<Nav.Link key={hLink.id} className="td_whiteT" href={hLink.link}>{hLink.text}</Nav.Link>
						))}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Header;