import React from 'react';
import nousagiIcon from './assets/nousagiIcon.png';
import tdWorkIcon from './assets/tdwIcon.png';
import tdHouseIcon from './assets/tdhIcon.png';
import tdEmailIcon from './assets/tdeIcon.png';
import tdPhoneIcon from './assets/tdpIcon.png';
import tdReactIcon from './assets/tdrIcon.png';
import tdProfPic from './assets/tdProfPic.png';

//TODO: TURN STUFF INTO COMPONENTS

function About() {
	return (
		<div className="container mt-5">
			<div className="row">
				<div className="col-12 col-lg-4 mt-2">
					<div className="container td_Abt td_fFontSz2">
						<img src={tdProfPic} alt="Profile Picture" className="td_AbtProfPic" />
						<h5 className="fw-bold text-center">Edward Eustaquio</h5>
						<img src={tdWorkIcon} alt="Work Icon" className="iconSz1" /> Software Engineer<br />
						<img src={tdHouseIcon} alt="Address Icon" className="iconSz1" /> Mandaluyong City, Philippines<br />
						<img src={tdEmailIcon} alt="Email Icon" className="iconSz1" /> edward.eustaquio@outlook.com<br />
						<img src={tdPhoneIcon} alt="Phone Icon" className="iconSz1" /> +63 995 273 5517<br />
						<br />
						<h3 className="fw-bold text-center">Skills</h3>
						<h4 className="fw-bold">Web:</h4>
						<ul className="tdAbtSkilList">
							<li>React</li>
							<li>Javascript</li>
							<li>JQuery</li>
							<li>PHP</li>
							<li>CSS</li>
							<li>Bootstrap</li>
							<li>HTML</li>
						</ul>
						<h4 className="fw-bold">Apps:</h4>
						<ul className="tdAbtSkilList">
							<li>C#</li>
							<li>SQL</li>
							<li>VB</li>
							<li>VBA</li>
							<li>SAP Macro</li>
						</ul>
					</div>
				</div>
				<div className="col-12 col-lg-8 mt-2">
					<div className="container td_Abt td_fFontSz2">
						<h3 className="mt-3 fw-bold"><img src={tdWorkIcon} alt="Work Icon" className="iconSz2" />Work Experience</h3>
						<br /><hr />
						<h5 className="fw-bold">IT Service Desk Analyst - Wipro</h5>
						<p className="tdAbtWEttl">8 months from Sep 2022 until May 2023</p>
						<p>Provide IT support service to an international bank. Mainly troubleshoot software issues and use our system tools to fix user's issues. We also remote their computers or provide blind troubleshooting assistance depending on the situation.</p>
						<br /><hr />
						<h5 className="fw-bold">IT Service Desk Analyst - eTeam</h5>
						<p className="tdAbtWEttl">4 months from May 2022 – Sep 2022</p>
						<p>Contractual IT SD work with eTeam for Wipro. I was absorbed after.</p>
						<br /><hr />
						<h5 className="fw-bold">Technical Support - Telus</h5>
						<p className="tdAbtWEttl">1 year from Dec 2020 – Nov 2021</p>
						<p>Worked on troubleshooting computer, appliances and software issues that are related to their internet service</p>
						<br /><hr />
						<h5 className="fw-bold">IT Assistant/Associate Software Engineer - IT Managers</h5>
						<p className="tdAbtWEttl">1 year from Jul 2019 – Jul 2020</p>
						<p>Improved and updated an existing sales management program for a French oil company. Troubleshoot and repaired the problems of a program after migrating it to a new System for a US pharmaceutical company. Improved and updated an HR webapp for a Japanese car company. Also provided IT Support and assistance whenever needed.</p>
						<br /><hr />
						<h5 className="fw-bold">Customer Service Representative - Concentrix</h5>
						<p className="tdAbtWEttl">8 months from Sep 2018 – Apr 2019</p>
						<p>Assisted customers and delivery partners when they encounter software issues or any issues in general when it comes to our app or service. Also created a program for our department to make it easier to manage Quality Analysis scores for both the team members and managers.</p>
						<br /><hr />
						<h5 className="fw-bold">Technical and Account Support - Alorica</h5>
						<p className="tdAbtWEttl">1 year and 5 months from Nov 2015 to Apr 2017</p>
						<p>Provided machine troubleshooting steps to callers and assisted them in managing their finances on their account with us. During my time in Alorica, I created a program that automatically extract data from different sources and consilidate it into an Excel file, that we can send to our customers. A task that when done manually, normally takes about 10-15 minutes but could take up to an hour depending on how big is the data. With my program, this task became a 2 min task.</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;