// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MyRoutes from './MyRoutes';
import Header from './Header';
import Home from './Home';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div className="App td_montAlt">
		<Header />
        <MyRoutes />
		<Footer />
      </div>
    </Router>
  );
}

export default App;