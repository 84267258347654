import React from 'react';
import HomeProfilePIc from './assets/i3.jpg';
import HomePic1 from './assets/tdCustomWebsitePic.png';
import HomePic2 from './assets/tdAppDevPic.png';

//TODO: TURN STUFF INTO COMPONENTS

function Home() {
	return (
		<div className="container text-center">
			<div className="row mt-5 justify-content-center">
				<img src={HomeProfilePIc} alt="Edward Pic" className="hPgProfPic" />
			</div>
			<div className="row">
				<div className="row fw-bold">
					<h1>Hi! I'm Ed, a passionate developer! ^_^</h1>
				</div>
				<div className="row">
					<div className="col-sm-3"></div>
						<p className="col-12 col-sm-6 td_fFontSz2">
							<em>Eager about learning and developing technology</em>
						</p>
					<div className="col-sm-3"></div>
				</div>
			</div>
			<div className="row mt-5">
				<h2>Let's program</h2>
			</div>
			<div className="row"><hr /></div>
			<div className="row mt-4">
				<div className="col-12 col-sm-6">
					<div className="homeText">
						<div className="row">
							<h2 className="fw-bold">Custom Website</h2>
							<p>Crafting customized websites that bring your unique vision to life. In today's digital age, having a strong online presence is essential for businesses and individuals alike. Let's go and create a website that exceeds your expectations</p>
							<div className="pb-2"><a href="/projects" className="btn btn-primary">Sample Projects</a></div>
						</div>
					</div>
				</div>
				<div className="col-12 col-sm-6">
					<img src={HomePic1} alt="Custom Website" className="homePic2" />
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-12 col-sm-6 order-sm-2">
					<div className="homeText">
						<div className="row">
							<h2 className="fw-bold">App Development</h2>
							<p>In a world where technology is constantly evolving, having the right application can be a game-changer. Let me help you by creating cutting-edge applications that empower businesses to thrive in today's digital landscape.</p>
							<div className="pb-2"><a href="/projects" className="btn btn-primary">Sample Projects</a></div>
						</div>
					</div>
				</div>
				<div className="col-12 col-sm-6 order-sm-1">
					<img src={HomePic2} alt="App Development" className="homePic2" />
				</div>
			</div>
		</div>
	);
}

export default Home;