import React from 'react';
import ReactIcon from './assets/reactIcon.ico';
import nousagiIcon from './assets/nousagiIcon.png';

function Footer(){	
	return (
		<div className="td_Footer td_cScheme2 td_fFontSz1">
			<div className="container">
				<div className="row"><h5>Contact Me Now!</h5></div>
				<div className="row">+63 995 273 5517</div>
				<div className="row"><a href="mailto:edward.eustaquio@outlook.com">edward.eustaquio@outlook.com</a></div>
				<div className="row">Mandaluyong City, Philippines</div>
				<div className="row">
					<h6>
						<img src={ReactIcon} alt="react icon" className="p-0 iconSz1" />
						React Web Site by Tirp
						<img src={nousagiIcon} alt="nousagi icon" className="p-0 iconSz1" />
					</h6>
				</div>
			</div>
		</div>
	);
}

export default Footer;